
import { apiGetexplain, apiClassfyUseup, apiVipactivate, apiBuyVip, apiUserUseCredit, apiUserInfo, apiRechargeCredit } from '@/api/api.js'

export default {
    components: {
    },
    data() {
        return {
            loading: true,
            swiperOption: {
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                slidesPerView: 4,
                // slidesPerView: 'auto',
                // spaceBetween: 10,
                // observer: true,//修改swiper自己或子元素时，自动初始化swiper
                // observeParents: true//修改swiper的父元素时，自动初始化swiper
            },
            isvip: null,
            isVipData: {
                // bg: require('@/assets/vip/bg.png'),
                vipLogo: require('@/assets/vip/vip-logo.png'),
                vipPrivilegeList: [
                    require('@/assets/vip/honourable.png'),
                    require('@/assets/vip/explain-in-detail.png'),
                    require('@/assets/vip/online-mentoring.png'),
                    require('@/assets/vip/send-60min.png'),
                    require('@/assets/vip/enabing-skills.png'),
                    require('@/assets/vip/web-seminar.png'),
                    require('@/assets/vip/walkman.png'),
                    require('@/assets/vip/privilege.png'),
                ],
                vipPrivilegeListEn: [
                    require('@/assets/vip/en/honourable.png'),
                    require('@/assets/vip/en/explain-in-detail.png'),
                    require('@/assets/vip/en/online-mentoring.png'),
                    require('@/assets/vip/en/send-60min.png'),
                    require('@/assets/vip/en/enabing-skills.png'),
                    require('@/assets/vip/en/web-seminar.png'),
                    require('@/assets/vip/en/walkman.png'),
                    require('@/assets/vip/en/privilege.png'),
                ]
            },
            notVipData: {
                // bg: require('@/assets/vip/gray-bg.png'),
                vipLogo: require('@/assets/vip/gray-vip-logo.png'),
                vipPrivilegeList: [
                    require('@/assets/vip/gray-honourable.png'),
                    require('@/assets/vip/gray-explain-in-detail.png'),
                    require('@/assets/vip/gray-online-mentoring.png'),
                    require('@/assets/vip/gray-send-60min.png'),
                    require('@/assets/vip/gray-enabing-skills.png'),
                    require('@/assets/vip/gray-web-seminar.png'),
                    require('@/assets/vip/gray-walkman.png'),
                    require('@/assets/vip/gray-privilege.png')
                ],
                vipPrivilegeListEn: [
                    require('@/assets/vip/en/gray-honourable.png'),
                    require('@/assets/vip/en/gray-explain-in-detail.png'),
                    require('@/assets/vip/en/gray-online-mentoring.png'),
                    require('@/assets/vip/en/gray-send-60min.png'),
                    require('@/assets/vip/en/gray-enabing-skills.png'),
                    require('@/assets/vip/en/gray-web-seminar.png'),
                    require('@/assets/vip/en/gray-walkman.png'),
                    require('@/assets/vip/en/gray-privilege.png')
                ]
            },
            userInfo: {
                headimgurl: '',
                vip: {
                    code: 0,
                    status: ''
                }
            },
            activeName: 'CREDIT',
            privilegeData: [],
            creditSum: 0,
            creditData: [],
            dialogVisibleExplain: false,
            creditExplainData: [],
            privilegeExplainData: [],
            creditPage: 1,
            creditPageCount: 0,
            privilegePage: 1,
            privilegePageCount: 0,
            dialogVisibleRenew: false,
            rc: 0,
            cdkey: '',
            renewType: ''
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.getUserInfo()
            this.getBuyVip()
            this.getUserUseCredit()
        },
        getUserInfo() {
            apiUserInfo().then((res) => {
                this.userInfo = res.data
                this.loading = false
                if (this.userInfo.vip.code == 1) {
                    this.isvip = true
                } else {
                    this.isvip = false
                }
            })
        },
        Getexplain() {
            apiGetexplain().then((res) => {
                this.privilegeExplainData = res.data
            })
        },
        // 激活
        vipactivate() {
            let _this = this
            let paramBase = {
                type: this.renewType,
                cdkey: this.cdkey
            }

            if (this.renewType == 'vip') {
                apiVipactivate(paramBase).then((res) => {
                    if (res.code == 200) {
                        this.dialogVisibleRenew = false;
                        this.isvip = true;
                        this.$layer.msg(res.msg)
                        setTimeout(() => {
                            // _this.$router.go(0)
                            window.location.reload()
                        }, 1300);
                    }
                })
            } else {
                apiRechargeCredit(paramBase).then((res) => {
                    if (res.code == 200) {
                        this.dialogVisibleRenew = false;
                        this.isvip = true;
                        this.$layer.msg(res.msg)
                        setTimeout(() => {
                            window.location.reload()
                        }, 1300);
                    }
                })
            }
        },
        GetEreditExplain() {
            apiClassfyUseup().then((res) => {
                this.creditExplainData = res.data
            })
        },
        getBuyVip() {
            apiBuyVip().then((res) => {
                this.privilegePageCount = res.data.count_page
                this.privilegeData = res.data.list
            })

        },
        getUserUseCredit() {
            let paramsBase = {
                page: this.creditPage,
            }
            apiUserUseCredit(paramsBase).then((res) => {
                this.loading = false
                this.creditPageCount = res.data.count_page
                this.creditSum = res.data.ask_nums
                this.creditData = res.data.use_list
            })
        },
        handleClick() {

        },
        clickUseExplain() {
            this.Getexplain()
            this.GetEreditExplain()
            this.dialogVisibleExplain = true
        },
        creditHandleCurrentChange(page) {
            this.creditPage = page
            this.getUserUseCredit()
        },
        privilegeHandleCurrentChange(page) {
            this.privilegePage = page
            this.getBuyVip()
        },
        clickRenew(type) {
            this.cdkey = ''
            this.renewType = type
            this.dialogVisibleRenew = true
        },
        toTaobao() {
            window.open('https://item.taobao.com/item.htm?spm=a1z10.5-c-s.w4002-21740808648.10.4fb6620akgBMBl&id=612634020091')
        }
    },
}